@import '../../styles/';

.info__form {
  display: flex;
  flex-direction: column;
  width: 1658px;
  gap: 80px;

  .info__form__fields {
    display: flex;
    gap: 48px;
    max-height: 675px;

    .info__section {
      width: 520px;
      height: 100%;

      .section__inputs {
        display: flex;
        flex-wrap: wrap;
        column-gap: 12px;
        row-gap: 16px;
        max-height: 100%;
      }

      .ant-row.ant-form-item {
        margin-bottom: 0;

        .ant-input-number {
          width: 100%;
        }

        .ant-form-item-label label {
          text-overflow: clip;
        }

        .ant-form-item-control-input-content {
          height: 32px;
        }
      }

      .remarks {
        width: 100%;
        
        .ant-form-item-label label {
          font-size: 12px;
          font-weight: 400;
          color: $color-black-text;
          line-height: normal;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
      }

      .side-menu__title {
        margin-bottom: 24px;
      }

      &:first-of-type {
        .ant-row.ant-form-item {
          width: 100%;
        }
      }

      &:nth-of-type(2) {
        .ant-row.ant-form-item {
          width: 165px;
        }
      }

      &:nth-of-type(3) {
        .ant-row.ant-form-item {
          width: 254px;

          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5) {
            width: 165px;
          }
        }
      }
    }
  }

  .ant-btn.ant-btn-default {
    align-self: flex-start;
    background-color: $color-blue-secondary;
    color: $color-white-primary;
    border: none;

    &:hover {
      background-color: $color-blue-secondary;
    }
  }
}

@import '../../styles/';

.home-page__layout {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/img/main-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .home-page__welcome-card {
    background-color: rgba(255, 255, 255, .9);
    padding: 88px 88px 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 88px;
    border: 1px solid $color-grey-secondary;

    .home-page__title {
      font-size: 48px;
      font-weight: 500;
      margin: 0;
    }

    .home-page__buttons {
      display: flex;
      align-items: center;
      gap: 24px;

      .ant-btn.ant-btn-default {
        background-color: $color-blue-secondary;
        color: $color-white-primary;
        border: none;
        font-size: 30px;
        line-height: 60px;
        padding: 16px 32px;
        height: auto;

        &:hover {
          background-color: $color-blue-secondary;
        }
      }
    }
  }
}

@import '../../../styles';

.graphs {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;

  .graphs-side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 320px;
    height: 100%;
    padding: 10px;
    background-color: $color-white-primary;

    .radio-group {
      flex-direction: column;
    }

    .controls__wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .ant-btn.ant-btn-default {
        display: flex;
        justify-content: center;
        background-color: $color-white-primary;
        margin-left: 0;
        border-color: $color-grey-secondary-extra-light;
        color: $color-black;

        &:hover {
          background-color: $color-blue-secondary;
          border-color: $color-blue-secondary;
          color: $color-white-primary;
        }
      }
    }
  }

  .graphs-container {
    width: calc(100% - 320px - 10px);
    height: 100%;
    background-color: $color-white-primary;
  }
}

@import './src/styles';

.flaws__statistics {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
  border: 1px solid $color-grey-secondary-extra-light;

  &.astm__spots {
    flex-direction: column;

    .column.first-column {
      border-right: none;
      display: flex;
      justify-content: space-between;

      .flaws__statistics__list {
        width: calc((100% - 150px) / 2);
        border-bottom: 1px solid $color-white-secondary;
        border-right: 1px solid $color-white-secondary;

        &:last-child {
          border-right: none;
        }

        &:not(:first-child) {
          .flaws__statistics__item {
            display: flex;
            justify-content: center;
            align-items: center;

            &:not(:first-child) {
              p {
                font-weight: 400;
              }
            }
          }
        }

        &:first-child {
          width: 150px;
        }

        .flaws__statistics__item {
          width: 100%;

          p:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }

  .column {
    //width: 100%;

    &.first-column {
      border-right: 1px solid $color-white-secondary;
    }

    &.second-column {
      width: 100%;

      .flaws__statistics__list {
        .flaws__statistics__item {
          width: 100%;
        }
      }
    }


  }

  .flaws__statistics__list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    line-height: 20px;
    padding: 0 16px;
    margin-bottom: 0;

    .flaws__statistics__item {
      display: flex;
      justify-content: space-between;
      width: 85px;
      padding: 5px 0;
      border-bottom: 1px solid $color-white-secondary;

      &:last-child {
        border-bottom: none;
      }

      p {
        margin-bottom: 0;

        &:first-child {
          font-weight: 600;
        }
      }
    }
  }
}
@import "src/styles";

.geometry__statistic {
  min-height: 100px;
  margin-top: 12px;
  border: 1px solid $color-grey-secondary-extra-light;
  padding: 2px 8px;

  .geometry__statistic__item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid $color-white-secondary;

    p {
      margin: 0;
    }

    &:last-child {
      border: none;
    }

    .geometry__statistic__item__title,
    .b-lines__item__title,
    .diagonals__item__title,
    .geometry__statistic__item__content {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      color: $color-black-primary;
    }

    .geometry__statistic__item__content {
      font-weight: 400;
      color: $color-black-light;
    }
  }

  .b-lines, .diagonals, .dimensions {
    display: block;

    .geometry__statistic__inner__list {
      .geometry__statistic__inner__item {
        display: flex;
        justify-content: space-between;
        padding: 2px 0;

        .geometry__statistic__inner__title {
          font-weight: 400;
        }
      }
    }
  }
}
.CRangeChangedWarning__modal {
  .ant-modal-content {
    .ant-modal-title {
      margin-left: 38px;
    }

    .ant-modal-body {
      min-height: 88px !important;
      padding-left: 70px !important;

      svg {
        position: absolute;
        top: 24px;
        left: 32px;
      }

      .CRangeChangedWarning__content {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
  }
}
@import './src/styles';

.radio-group {
  display: flex;
  gap: 4px;
  width: 100%;

  .ant-radio-wrapper {
    text-align: center;
    flex-basis: 100%;
    margin-right: 0;
    height: 24px;
    line-height: 22px;
    background-color: $color-white-primary;
    border: 1px solid $color-grey-secondary-extra-light;
    transition: background-color .3s ease-in;

    .ant-radio {
      & + span {
        width: 100%;
      }

      .ant-radio-input {
        opacity: 0;
      }

      .ant-radio-inner {
        display: none;
      }
    }

    &:not(.ant-radio-wrapper-checked):hover {
      background-color: $color-grey-primary-extra-light;
    }
  }

  .ant-radio-wrapper-checked {
    background-color: $color-blue-primary;
    border-color: transparent;
    color: $color-white-primary;
  }

  .ant-radio-wrapper-disabled {
    background-color: $color-grey-primary-light;
    color: $color-grey-primary-deep;
  }
}

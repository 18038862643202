@import '../../../styles';

.height-analysis-controller {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  padding: 12px;
  background-color: $color-white-primary;

  .item__list {
    max-height: 85vh;
    overflow-y: auto;

    .item__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ant-form-item {
        margin-bottom: 0;
      }

      .item__legend {
        width: 16px;
        height: 16px;
      }
    }
  }
}

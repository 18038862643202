@import './src/styles';

.switch-group {
  .ant-row {
    margin-bottom: 12px;

    .ant-form-item-label {
      label {
        font-size: 16px;
        line-height: 24px;
        text-transform: initial;
        margin-bottom: 0;
        color: $color-black-primary;
        width: 100%;
        text-align: center;

        &::after {
          display: none;
        }
      }
    }

    .ant-form-item-control {
      align-items: center;

      .ant-form-item-control-input {
        min-height: 22px;

        button {
          height: 22px;
          min-width: 44px;
          background-color: $color-grey-secondary;

          &.ant-switch-checked {
            background-color: $color-blue-primary;
          }

          .ant-switch-handle {
            top: 1px;
          }
        }
      }
    }
  }
}

@import './src/styles';

.segregation__menu__statistics {
  .seg__statistics__list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    line-height: 20px;
    padding: 0 16px;
    margin-bottom: 0;

    .seg__statistics__item {
      display: flex;
      justify-content: space-between;
      width: 85px;
      padding: 5px 0;
      border-bottom: 1px solid $color-white-secondary;

      &:last-child {
        border-bottom: none;
      }

      p {
        margin-bottom: 0;

        &:first-child {
          font-weight: 600;
        }
      }
    }

    &.second-column {
      .seg__statistics__item {
        width: 100%;
      }
    }
  }
}
@import './src/styles';

.report {
  flex-direction: column;
  width: 100%;
  background-color: $color-white-primary;
  padding: 16px;

  .side-menu__title {
    margin-bottom: 12px;
  }

  .report__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .ant-row {
      margin-bottom: 0;

      .ant-checkbox-wrapper {
        color: $color-black-primary;
        font-size: 16px;
        line-height: 24px;
        height: auto;

        .ant-checkbox .ant-checkbox-inner {
          border-color: $color-grey-secondary-extra-light;
        }
      }
    }

    .ant-checkbox-wrapper {
      .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
        border-color: $color-blue-secondary;
      }
    }

    button {
      align-self: flex-start;
      height: 40px;
      background-color: $color-blue-secondary;
      border-color: $color-blue-secondary;

      &:hover {
        background-color: $color-blue-secondary;
        border-color: $color-blue-secondary;
      }

      &:focus {
        background-color: $color-blue-secondary;
        border-color: $color-blue-secondary;
      }
    }
  }
}

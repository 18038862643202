@import './src/styles';

.progress {
  position: absolute;
  bottom: 20px;
  left: 0;
  height: 32px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  z-index: 202;

  .progress-container {
    display: flex;
    background-color: $color-grey-primary-extra-light;
    width: 100%;
    height: 8px;
    margin-right: 40px;

    .progress-indicator {
      background-color: $color-blue-secondary;
      border-radius: 0 100px 100px 0;
      height: 100%;
      transition: all 0.8s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    }
  }
}
@import './src/styles';

.segregation__menu__analysis {
  margin-bottom: 24px;

  .segregation__menu__analysis__controls {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 12px;

    .ant-btn.ant-btn-default {
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-black-primary;
      width: 100%;
      height: 24px;
      padding: 0 5px;
      border-color: $color-grey-secondary-extra-light;
      background: transparent;
      transition: background-color 0.3s ease-in, border-color 0.3s ease-in;

      &:last-child {
        margin-bottom: 0;
        border-left: 1px solid inherit;
      }

      & + .ant-btn {
        margin-left: 0;
      }

      &:hover {
        background-color: $color-blue-secondary;
        border-color: $color-blue-secondary;
        color: $color-white-primary;
      }

      &:focus {
        color: inherit;
        border-color: $color-grey-secondary-extra-light;
        background-color: inherit;
      }

      &:disabled {
        background-color: $color-grey-primary-light !important;
        color: $color-grey-primary-deep !important;
      }

      &:not([disabled]):hover {
        background-color: $color-blue-secondary !important;
        border-color: transparent !important;
        color: $color-white-primary !important;
      }
    }
  }
}

$color-white-primary: #fff;
$color-white-secondary: #f0f0f0;
$color-red: #ff0000;
$color-yellow: #ffff00;
$color-orange: #ffa500;
$color-green-primary: #008000;
$color-black: #000000;
$color-black-primary: #121212;
$color-black-secondary: #333333;
$color-black-light: #434343;
$color-black-text: #393939;
$color-blue-primary: #0074bb;
$color-blue-secondary: #0075be;
$color-grey-primary: #e0e0e0;
$color-grey-primary-deep: #595959;
$color-grey-primary-extra-light: #f4f4f4;
$color-grey-primary-light: #f5f5f5;
$color-grey-secondary: #bfbfbf;
$color-grey-secondary-deep: #8e8e8e;
$color-grey-secondary-extra-light: #d9d9d9;
$color-philippine-grey: #8d8d8d;
$color-yellow-primary: #ffff00;
$color-green-light: #9eff00;
$color-red-primary: #ff0000;
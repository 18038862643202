.projection-item.aScanView .projection-item__container {
  .projection-item__wrapper {
    margin-right: 0;
  }

  .projection-graph {
    width: 100%;

    .legend--y {
      left: -40px;
    }
  }
}
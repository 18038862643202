.snapshots__modal {
  .titles__wrapper {
    display: flex;
    flex-wrap: wrap;

    .ant-form-item {
      width: 50%;
    }
  }
}

@import '../../styles/index';

.common__graph__wrapper {
  display: flex;
  align-items: flex-end;
  width: calc(100% - 380px - 88px - 20px);
  height: 100%;
  background-color: $color-white-primary;
  padding: 16px;

  #commonGraphInnerWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .range-bar {
    height: calc(100% - 40px);
  }
}

@import 'src/styles/vars';

.range-slider {
  max-width: 250px;
  width: 100%;
  height: 4px;
  border-radius: 100px;
  background-color: $color-grey-primary-light;
  position: relative;

  input[type='range'] {
    display: none;
  }

  &.up .range-slider__range {
    background-position: 0 100%;
  }

  &.down .range-slider__range {
    background-position: 0 0;
  }

  &[data-vertical] {
    height: calc(100% - 40px - 64px - 24px);
    width: 4px;

    .range-slider__thumb {
      width: 20px;
      height: 6px;
    }

    .range-slider__range {
      width: 20px;
    }
  }

  .range-slider__thumb {
    height: 20px;
    width: 6px;
    border-radius: 4px;
    background-color: $color-grey-secondary;

    &:hover {
      cursor: row-resize;
    }
  }

  .range-slider__range {
    background-color: $color-grey-secondary-extra-light;
  }
}
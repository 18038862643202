@import '../../../../styles/index';

.distance__modify__selection {
  display: flex;
  gap: 80px;
  margin-bottom: 24px;
}

.distance__modify__zones {
  .modify__zones__radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 0;

    .ant-radio-wrapper {
      height: 22px;
      line-height: 22px;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        width: 16px;
        height: 16px;
        background-color: black;
      }
    }
  }
}

.distance__modification-options {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  button {
    width: calc(50% - 4px);
    height: 24px;
    justify-content: center;

    &:last-child {
      width: 100%;
    }

    & + .ant-btn {
      margin-left: 0;
    }

    &[disabled] {
      background-color: $color-grey-primary-light;
      border-color: $color-grey-primary-light;
      color: $color-black-primary;
    }
  }
}

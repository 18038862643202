@import './src/styles';

.remarks {
  .ant-row.ant-form-item {
    .ant-form-item-label {
      label {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        color: $color-blue-secondary;
        margin-bottom: 12px;
        text-transform: none;
      }
    }

    .ant-form-item-control {
      textarea {
        height: 92px;
        //height: 100%;
        resize: none;
      }
    }
  }
}
.recipe-flaws-zones__modal {
  .ant-modal-body {
    display: flex;
    gap: 16px;

    .recipe__set-flaws-zone {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .sub-menu__title {
        margin-bottom: 0;
      }

      .ant-row.ant-form-item {
        width: 200px;
        margin-bottom: 0;
      }
    }
  }
}

@import './src/styles';

.flaws__menu__control {
  margin-bottom: 12px;

  .flaws__menu__settings__controls {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;

    .ant-row {
      width: 100%;

      .ant-form-item-control-input-content {
        height: 32px !important;

        .ant-input-number {
          width: 100% !important;
        }
      }

    }
  }

  .ant-row {
    width: 100%;

    .ant-form-item-control-input-content {
      .ant-input-number {
        width: 100% !important;
      }
    }

  }
}

@import '../../../../styles/';

.distance__statistics__table {
  border: 1px solid $color-grey-secondary-extra-light;
  padding: 0 8px;
  max-height: 325px;
  overflow-y: auto;

  .distance__statistics__table__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-white-secondary;

    &:first-child .distance__statistics__table__cell {
      p {
        font-weight: 600;
      }

      &:nth-child(3) p {
        max-width: 100px;
        text-align: center;
      }

      &:nth-child(4) p {
        max-width: 80px;
        text-align: center;
      }
    }

    .distance__statistics__table__cell {
      padding: 10px 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      width: 35%;

      p {
        margin: 0;
      }

      &:nth-child(1) {
        width: 20%;
        font-weight: 600;
      }

      &:nth-child(2) {
        width: 10%;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

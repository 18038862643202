@import './src/styles/vars';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  > iframe {
    display: none !important;
  }

  overflow: hidden;
}

li {
  list-style: none;
}

.ant-layout-header {
  .ant-col {
    &:nth-child(4) {
      display: none;
    }

    &:first-child {
      width: auto;
      height: auto;
      padding-left: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &:nth-child(3) {
      width: 56px;
    }

    .ant-row .ant-form-item-control .ant-select-selector {
      font-size: 16px;
      line-height: 24px !important;
      padding: 0 10px !important;
    }
  }
}

.ant-layout-content {
  div {
    .ant-row-middle + .ant-row:not(.ant-row-middle) {
      height: 100%;
    }

    .ant-row-middle {
      display: none;
    }
  }

  .ant-layout-sider {
    display: none;
  }

  .router {
    height: 100%;
    position: relative;
  }
}

body .ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
  color: $color-black-primary;
}

.ant-modal {
  .ant-modal-content {
    .ant-modal-close {
      top: 10px;
      right: 12px;
    }

    .ant-modal-header {
      height: auto !important;
      padding: 24px 32px !important;

      .ant-modal-title {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 24px !important;
      }
    }

    .ant-modal-body {
      min-height: 140px;
      padding: 0 32px !important;
    }

    .ant-modal-footer {
      height: auto !important;
      padding: 24px 32px !important;

      button {
        display: flex;
        justify-content: center;
        min-width: 96px;
        height: 40px !important;
        margin: 0 !important;
        font-size: 16px !important;
        line-height: 24px !important;

        & + button {
          margin-left: 16px !important;
        }

        &:first-child {
          order: 2;
          margin-left: 16px !important;
          background-color: transparent;
          border: 1px solid $color-grey-secondary-extra-light;
          transition: background-color 0.3s ease-out, border-color 0.3s ease-out;

          span {
            color: $color-black-primary !important;
          }

          &:hover {
            background-color: $color-grey-primary-extra-light;
            border-color: transparent;
          }
        }

        &:not(.ant-button-primary) {
          color: $color-black-primary;
        }
      }
    }
  }
}

.ant-tabs-nav {
  padding: 0 !important;
  margin-bottom: 12px !important;
  padding: 0 !important;
  margin-bottom: 12px !important;

  .ant-tabs-tab {
    padding: 0 !important;
    margin-right: 16px !important;
    .ant-tabs-tab {
      padding: 0 !important;
      margin-right: 16px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
  &:last-child {
    margin-right: 0 !important;
  }
}

[data-amplify-authenticator] {
  height: 100vh;

  [data-amplify-form] {
    padding: 2rem 2rem 0 2rem;

    &[data-amplify-authenticator-resetpassword] {
      padding: 2rem 2rem 1rem 2rem;
    }
  }

  [data-amplify-container] {
    place-self: start center;
    padding-top: 100px;
  }

  .amplify-tabs {
    height: 40px;
    background-color: #161616;
    background-image: url('../assets/img/HDscan-logo.svg');
    background-repeat: no-repeat;
    background-position: 32px 50%;

    button {
      display: none;
    }
  }

  [data-amplify-router] {
    border: 0;
    border-radius: 2px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
      0px 3px 6px -4px rgba(0, 0, 0, 0.12);

    .amplify-flex.amplify-field.amplify-textfield {
      gap: 0;
    }

    .amplify-label {
      font-size: 12px;
      font-weight: 400;
      color: $color-black-text;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .amplify-input {
      height: 32px;
      padding: 0px 16px;
      font-size: 14px;
      color: #161616;
      background-color: $color-grey-primary-extra-light;
      border: 1px solid transparent;
      border-bottom-color: $color-philippine-grey;
      line-height: 32px;
      border-radius: 0;
      transition: border 0.3s;

      &:focus {
        outline: none;
        background-color: $color-grey-primary-extra-light;
        border-color: $color-blue-secondary;
        box-shadow: none;
      }
    }

    .amplify-button--primary {
      height: 40px;
      background-color: $color-blue-secondary;
      border-color: $color-blue-secondary;
      color: $color-white-primary;
      padding: 0px 16px;
      font-size: 14px;
      font-weight: 400;
      width: max-content;
      border-radius: 0;
      margin-top: 8px;
    }

    .amplify-field__show-password {
      height: 32px;
      border: 1px solid transparent;
      border-bottom-color: $color-philippine-grey;
      background-color: $color-grey-primary-extra-light;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }
    }
    .amplify-button--link {
      color: $color-blue-secondary;

      &:hover {
        background-color: unset;
        text-decoration: underline;
        color: $color-blue-secondary;
      }

      &:focus {
        background: unset;
        border: unset;
        box-shadow: unset;
        outline: none;
      }
    }
  }
}

.side-menu__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: $color-blue-secondary;
}

.sub-menu__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $color-blue-secondary;
  margin-bottom: 12px;
}

.page-container {
  height: 100%;
  display: flex;
  gap: 10px;
}

.sample_name {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: $color-white-primary;
  z-index: 10;
}

.auto-threshold-btn {
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-black-primary !important;
  width: 100%;
  height: 24px !important;
  padding: 0 5px;
  border-color: $color-grey-secondary-extra-light !important;
  background: transparent !important;
  transition: background-color 0.3s ease-in, border-color 0.3s ease-in;

  &:last-child {
    margin-bottom: 0;
    border-left: 1px solid inherit;
  }

  & + .ant-btn {
    margin-left: 0;
  }

  &:hover {
    background-color: $color-blue-secondary;
    border-color: $color-blue-secondary;
    color: $color-white-primary;
  }

  &:focus {
    color: inherit;
    border-color: $color-grey-secondary-extra-light;
    background-color: inherit;
  }
}

.ant-tooltip {
  &.display-none {
    display: none;
  }

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background: $color-black-secondary !important;
      }
    }

    .ant-tooltip-inner {
      border: 1px solid $color-black-secondary;
      font-size: 12px;
    }
  }
}

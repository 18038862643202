@import '../../styles';

.images-snapshot__container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  width: 100%;
  padding: 16px;
  background-color: $color-white-primary;

  .images-snapshot__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: $color-black;
    margin-bottom: 0;
  }

  .images-snapshot__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .images-snapshot__buttons {
      display: flex;
      gap: 10px;

      button {
        display: flex;
        justify-content: center;
        background-color: $color-white-primary;
        margin-left: 0;
        border-color: $color-grey-secondary-extra-light;
        color: $color-black;

        &:hover {
          color: $color-white-primary;
          background-color: $color-blue-secondary;
          border-color: $color-blue-secondary;
        }

        &:focus {
          color: inherit;
          border-color: $color-grey-secondary-extra-light;
          background-color: inherit;
        }

        &:disabled {
          background-color: $color-grey-primary-light;
          border-color: $color-grey-secondary-extra-light;
          color: $color-philippine-grey;
        }
      }
    }

    span {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .images-snapshot__snapshot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: calc(
      100dvh - 48px - 72px - 10px - 16px - 28px - 24px - 32px - 24px - 16px -
        10px
    );

    .ReactCrop {
      max-height: inherit;
    }

    img {
      max-width: 100%;
      max-height: inherit;
      object-fit: contain;
      background: $color-white-primary;
    }
  }
}

@import './src/styles';

.seg__statistics__spots {
    display: flex;
    padding: 2px 0;
    border: 1px solid $color-grey-secondary-extra-light;
    margin-bottom: 0;

    .column {
        &.first-column {
            border-right: 1px solid $color-white-secondary;
        }

        &.second-column {
            width: 100%;
        }
    }

    &.astm__spots {
        flex-direction: column;

        .column {
            &.first-column {
                border-right: none;
                display: flex;
                justify-content: space-between;

                .seg__statistics__list {
                    border-right: 1px solid $color-white-secondary;
                    //width: 60px;
                    width: calc((100% - 150px) / 3);
                    border-bottom: 1px solid $color-white-secondary;


                    &:first-child {
                        width: 150px;
                    }

                    &:last-child {
                        border-right: none;
                    }

                    .seg__statistics__item {
                        width: 100%;
                    }

                    &:not(:first-child) {
                        .seg__statistics__item {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:not(:first-child) {
                                p {
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }

            }

            &.second-column {
                .seg__statistics__list {
                    .seg__statistics__item {
                        p {
                            width: calc((100% - 140px) / 3);

                            &:first-child {
                                width: 140px;
                            }

                            &:not(:first-child) {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0 16px;

                            }

                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &.gost__spots {
        .column {
            &.first-column {
                .seg__statistics__item {
                    width: 120px;
                }
            }
        }
    }
}

.ant-modal.autoanalysis-start__modal {
  .ant-modal-content .ant-modal-body {
    min-height: auto;

    .dynamic-table .table-body {
      max-height: 120px;
      overflow-y: auto;
    }
  }
}

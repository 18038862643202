@import '../../styles/';

.recipes__editor {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: $color-white-primary;
  gap: 44px;

  p {
    margin-bottom: 0;
  }

  .recipe__analysis {
    display: flex;
    flex-direction: column;
  }

  .recipe__form-section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .side-menu__title {
      margin-bottom: 4px;
    }

    .sub-menu__title {
      margin-bottom: 4px;
    }

    .ant-row.ant-form-item {
      margin-bottom: 0;
      width: 160px;

      .ant-form-item-control .ant-form-item-control-input {
        min-height: 25px;

        .ant-form-item-control-input-content {
          max-height: 32px;

          &:has(> textarea, > .ant-radio-group) {
            max-height: 100%;
          }
        }
      }

      .ant-form-item-no-colon {
        margin-bottom: 5px;
        overflow: auto;
        white-space: nowrap;
        text-overflow: clip;
      }

      .ant-col.ant-form-item-label:has(+ .ant-form-item-control .ant-checkbox-wrapper) {
        .ant-form-item-no-colon {
          margin-bottom: 0px;
        }
      }

      .ant-checkbox-wrapper {
        height: 22px;
        line-height: 22px;
        display: flex;
        align-items: flex-end;

        .ant-checkbox + span {
          line-height: 18px;
        }
      }

      .ant-radio-wrapper {
        height: 28px;
      }
    }

    .recipe__analysis-form {
      display: flex;
      flex-direction: column;
      gap: 17px 28px;
      height: 100%;
      flex-wrap: wrap;
      width: 340px;

      p {
        font-size: 12px;
        font-weight: 400;
        color: $color-black-text;
        text-transform: uppercase;
      }

      .recipe__analysis-types .ant-radio-group {
        height: auto;
        margin-left: 4px;
      }

      .recipe__flaws-zones {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border: 1px solid $color-grey-secondary-extra-light;
        color: $color-black-text;
        padding: 4px;

        .recipe__flaws-zones__zone {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
      }

      .ant-btn {
        margin-bottom: 4px;
      }
    }

    &:first-child {
      flex: 0;

      .ant-row.ant-form-item {
        width: 218px;
      }
    }

    &:last-child {
      .ant-row.ant-form-item {
        width: 180px;
      }
    }

    .ant-btn.ant-btn-default {
      display: flex;
      justify-content: center;
      margin-left: 0;
      background-color: $color-white-primary;
      border-color: $color-grey-secondary-extra-light;
      color: $color-black;

      &:hover {
        color: $color-white-primary;
        background-color: $color-blue-secondary;
        border-color: $color-blue-secondary;
      }

      &:focus {
        color: inherit;
        border-color: $color-grey-secondary-extra-light;
        background-color: inherit;
      }

      &[disabled] {
        color: $color-black-primary;
        background-color: $color-grey-primary-light;
        border-color: $color-grey-secondary-extra-light;

        &:hover {
          background-color: $color-grey-primary-light;
          border-color: $color-grey-secondary-extra-light;
        }
      }
    }

    .button-tooltip-container {
      width: 100%;

      .ant-btn {
        width: 100%;
      }
    }
  }

  .ant-radio-group {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: column;
  }
}

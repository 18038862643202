@import './src/styles';

.ant-modal.recipes-list__modal {
  .ant-modal-content .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-bottom: 32px !important;

    .table-body {
      max-height: 480px;
      overflow-y: auto;
    }
    
    .recipes-list__modal__buttons {
      display: flex;
      gap: 24px;
    }

    .ant-btn.ant-btn-default {
      display: flex;
      justify-content: center;
      margin-left: 0;
      background-color: $color-white-primary;
      border-color: $color-grey-secondary-extra-light;
      color: $color-black;

      &:hover {
        color: $color-white-primary;
        background-color: $color-blue-secondary;
        border-color: $color-blue-secondary;
      }

      &:focus {
        color: inherit;
        border-color: $color-grey-secondary-extra-light;
        background-color: inherit;
      }

      &[disabled] {
        color: $color-black-primary;
        background-color: $color-grey-primary-light;
        border-color: $color-grey-secondary-extra-light;

        &:hover {
          background-color: $color-grey-primary-light;
          border-color: $color-grey-secondary-extra-light;
        }
      }
    }
  }
}

@import './src/styles';
$range-bar-height: calc(
  100vh - 48px - 72px - 20px - 44px - 166px
); // 100vh - header - toolbar - layout paddings - palette bar paddings - switch group height

.palette-bar {
  background-color: $color-white-primary;
  padding: 16px 8px;
  max-width: 88px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-btn.ant-btn-default {
    //width: 72px;
    width: 100%;
    padding: 4px 8px;
    background-color: $color-black-secondary;
    border-color: $color-black-secondary;
    line-height: 22px;
  }

  .range-bar {
    gap: 8px;
    height: $range-bar-height;

    .range-slider {
      width: 12px;
      border-radius: 0;

      &.monochrome .range-slider__range {
        background-image: url('../../assets/img/monochrome-gradient.png');
      }

      &.monochrome.inverted .range-slider__range {
        background-image: url('../../assets/img/reversed-monochrome-gradient.png');
      }

      &.cropped {
        overflow: hidden;
        border-radius: 0;

        & .range-slider__range {
          background-color: transparent;
        }

        & .range-slider__thumb {
          &:before {
            content: '';
            width: 22px;
            background-color: $color-white-primary;
            display: block;
            position: absolute;

            left: 0;
            height: 100vh;
            z-index: 1;
          }

          &:after {
            content: '';
            width: 12px;
            background-color: $color-grey-primary-light;
            display: block;
            position: absolute;
            z-index: 2;
            left: 5px;
            height: 100vh;
          }

          &[data-upper] {
            &:after,
            &:before {
              top: 6px;
            }
          }

          &[data-lower] {
            &:after,
            &:before {
              bottom: 6px;
            }
          }
        }
      }

      &[data-vertical] {
        height: 100%;
        max-height: unset;

        &.cropped {
          width: 22px;
          background-image: url('../../assets/img/gradient.png');
          background-size: 20px 100%;
          background-position: center;
          background-color: transparent;

          &.inverted {
            background-image: url('../../assets/img/reversed-gradient.png');
          }


          & .range-slider__range {
            background-image: none;
            background-size: unset;
          }

          &.monochrome {
            background-image: url('../../assets/img/monochrome-gradient.png');
          }

          &.monochrome.inverted {
            background-image: url('../../assets/img/reversed-monochrome-gradient.png');
          }
        }
      }

      .range-slider__thumb {
        width: 22px;
      }

      &.inverted .range-slider__range {
        background-image: url('../../assets/img/reversed-gradient.png');
      }

      .range-slider__range {
        background-image: url('../../assets/img/gradient.png');
        background-color: transparent;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-attachment: fixed;
        border-radius: 2px;
        overflow: hidden;

        //&.inverted {
        //  background-image: url('../../assets/img/reversed-gradient.png');
        //}
      }
    }
  }
}

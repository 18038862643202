@import './src/styles';

.file-loader__modal {
  .ant-modal-content {
    font-size: 14px;

    .ant-modal-body {
      min-height: 0 !important;

      .ant-form-item-control {
        margin-bottom: 16px;

        .ant-radio-group {
          flex-direction: column;
          margin-right: 0;
          height: auto;

          label {
            width: 100%;
            text-align: center;

            &:not(:first-child)::before {
              left: 0;
            }

            & + label {
              border-top: none;
            }
          }
        }
      }

      .ant-pagination {
        margin-top: 16px;
        justify-content: center;
      }

      //.ant-row:last-child {
      //  position: absolute;
      //  bottom: 0;
      //
      //  label.ant-checkbox-wrapper {
      //    .ant-checkbox {
      //      .ant-checkbox-inner {
      //        border-color: $color-grey-secondary-extra-light;
      //      }
      //
      //      & + span {
      //        user-select: none;
      //      }
      //    }
      //  }
      //}
    }
  }
}

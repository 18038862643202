@import '../../../styles/';

.db-report__modal .ant-modal-content {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .report__logo__change {
      gap: 12px;
      flex-wrap: wrap;

      .report__logo {
        gap: 8px;
      }
    }

    .ant-btn.ant-btn-default {
      align-self: flex-start;
      background-color: $color-white-primary;
      color: $color-black-primary;
      border-color: $color-grey-secondary-extra-light;
      height: 40px;

      &:hover {
        color: $color-white-primary;
        background-color: $color-blue-secondary;
        border-color: $color-blue-secondary;
      }
    }
  }

  .ant-modal-footer button:first-child {
    color: $color-black-primary;
  }
}

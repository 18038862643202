@import './src/styles';

.settings-page {
  width: 100%;
  background-color: $color-white-primary;
  padding: 16px;
  gap: 10px;
  flex-direction: column;

  .ant-btn.ant-btn-default {
    align-self: flex-start;
    background-color: $color-blue-secondary;
    color: $color-white-primary;
    border: none;
    
    &:hover {
      background-color: $color-blue-secondary;
    }
  }
  .ant-row.ant-form-item {
    margin-bottom: 0;
    
    .ant-col.ant-form-item-control {
      height: 32px;
    }
  }
}

@import './src/styles';

.projection-graph {
  position: relative;
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;

  #sliceB,
  #sliceC,
  #sliceD,
  #aScanView {
    //width: calc((100vw - 40px - 88px) / 2 - 32px - 72px - 35px);
    width: 100%;
    //height: calc((100vh - 48px - 72px - 20px) / 2 - 32px - 10px - 22px);
    height: 100%;
    display: block;

    path,
    .tick line {
      stroke: $color-grey-secondary-deep;
    }

    text {
      fill: $color-grey-secondary-deep;
      font-size: 12px;
      user-select: none;
    }

     .distance-group {
        text {
          fill: $color-black;
          font-size: 16px;
        }
     }
  }

  .legend--y,
  .legend--x {
    font-size: 14px;
    line-height: 22px;
    color: $color-grey-secondary-deep;
  }

  .legend--x {
    margin-top: 8px;
  }

  .legend--y {
    position: absolute;
    top: 40%;
    //top: 168px;
    left: -82px;
    transform: rotate(-90deg);
  }
}

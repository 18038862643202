@import '../../../../../styles';

.snapshot-controller {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 320px;
  padding: 10px;
  background-color: $color-white-primary;

  .snapshot-controller__content {
    max-height: 90%;
    overflow-y: auto;
  }
}

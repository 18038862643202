.slice__container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px 10px;

  .projection-item {
    width: 100%;
    height: 100%;
  }
}
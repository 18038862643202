@import '../../../styles/';

.dynamic-table {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: $color-black-text;
  border: 1px solid $color-grey-primary;
  width: 100%;

  .table-header {
    display: flex;
    flex-direction: row;
    background-color: $color-grey-primary-extra-light;
    text-transform: uppercase;
    font-size: 12px;
    height: 40px;
    border-bottom: 1px solid $color-grey-primary;

    .table-cell {
      //text-align: left;
      vertical-align: bottom;

      .ant-row.ant-form-item {
        margin-bottom: 0;

        .ant-checkbox-wrapper {
          line-height: 26px;
          height: 28px;

          .ant-checkbox + span {
            display: none;
          }
        }
      }

      div svg {
        width: 16px;
      }
    }
  }

  .table-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    // text-align: left;
    overflow: hidden;
    padding: 4px 8px;
    // white-space: nowrap;
    // word-wrap: break-word;
    gap: 8px;
    user-select: none;

    div svg {
      font-size: 16px;
    }

    .recipe-row {
      display: flex;
      flex-direction: column;
      gap: 2px;
      max-height: 70px;
      width: 100%;
      overflow-y: auto;

      .recipe-row__item {
        user-select: none;

        &.recipe-item__selected {
          color: $color-blue-secondary;
        }

        &.recipe-item__disabled {
          cursor: not-allowed;
          opacity: 0.7;
        }
      }
    }
  }

  .table-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $color-grey-primary;
    min-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      cursor: pointer;
      background-color: $color-grey-primary-extra-light;
    }

    &.table-row__current {
      background-color: $color-grey-secondary-extra-light;

      &:hover {
        background-color: $color-grey-secondary-extra-light;
      }
    }

    &.table-row__selected {
      background-color: $color-blue-secondary;
      color: $color-white-primary;

      &:hover {
        background-color: $color-blue-secondary;
      }

      .recipe-row__item {
        color: $color-white-primary;

        &.recipe-item__selected {
          color: $color-black-secondary;
        }
      }
    }

    &.table-row__disabled {
      background-color: $color-grey-primary-light;
      color: $color-grey-primary-deep;
      pointer-events: none;

      svg {
        font-size: 16px;
        color: $color-grey-primary-deep;
      }

      &:hover {
        background-color: $color-grey-primary-light;
      }
    }
  }
}

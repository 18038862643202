@import 'src/styles';

.change-parameters__modal {
  &.ok-hidden {
    .ant-modal-content {
      .ant-modal-footer {
        button:last-child {
          opacity: 0;
        }
      }
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      min-height: 100px;

      .ant-form-item {
        margin-bottom: 24px;

        .ant-form-item-control {
          .ant-radio-group {
            margin-right: 0;

            label {
              width: 50%;
              margin-right: 0;

              .ant-radio-inner {
                border-color: $color-grey-secondary-extra-light;
              }

              &.ant-radio-wrapper-checked {
                .ant-radio-inner {
                  border-color: $color-blue-secondary;
                }
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      button:first-child {
        order: 2;
        margin-left: 16px !important;
        background-color: transparent;
        border: 1px solid $color-grey-secondary-extra-light;
        transition: background-color 0.3s ease-out, border-color 0.3s ease-out;

        span {
          color: $color-black-primary !important;
        }

        &:hover {
          background-color: $color-grey-primary-extra-light;
          border-color: transparent;
        }
      }
    }
  }

  .change-parameters__wrapper {
    display: flex;
    gap: 16px 12px;
    flex-wrap: wrap;

    .ant-row.ant-form-item {
      width: calc(50% - 6px);
      margin-bottom: 0;

      .ant-form-item-control,
      .ant-form-item-label {
        max-width: 100% !important;
      }

      .ant-form-item-label {
        label {
          font-size: 12px;
          line-height: 14px;
          color: $color-grey-primary-deep;
        }
      }
    }
  }
}

@import '../../styles';

.adapt__image__graph__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 320px - 10px);
  height: 100%;
  background-color: $color-white-primary;
  padding: 16px;

  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    align-self: flex-start;
    margin-bottom: 0;
  }

  .common__graph {
    height: calc(100% - 40px);
 
    .legend--x {
      left: 0;
      &.legend--x--top {
        margin-top: 0;
      }
    }

    .legend--y {
      position: absolute;
      left: -56px;
      top: 45%;
      width: 78px;

      &.legend--y--right {
        left: auto;
        right: -54px;
        width: 88px;
      }
    }
  }
}

@import './src/styles';

.dropdown__menu__list {
  width: 100%;

  li {
    width: 100%;
    background-color: $color-white-primary;
    height: 32px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    transition: background-color .3s;

    &:hover {
      background-color: $color-grey-primary-extra-light;
    }
  }
}
@import './src/styles/';

.recipe__container {
  height: 100%;
  padding: 10px;

  .ant-tabs {
    .ant-tabs-nav {
      margin: 0px !important;

      .ant-tabs-tab {
        margin: 0px !important;
        padding: 0px 16px !important;
      }
    }

    .ant-tabs-content-holder div[role='tabpanel'] {
      //background-color: $color-grey-primary;
      //background-color: red;
      padding: 16px;
    }
  }

  .ant-btn.ant-btn-default {
    display: flex;
    justify-content: center;
    margin-left: 0;
    background-color: $color-white-primary;
    border-color: $color-grey-secondary-extra-light;
    color: $color-black;

    &:hover {
      color: $color-white-primary;
      background-color: $color-blue-secondary;
      border-color: $color-blue-secondary;
    }

    &:focus {
      color: inherit;
      border-color: $color-grey-secondary-extra-light;
      background-color: inherit;
    }

    &[disabled] {
      color: $color-black-primary;
      background-color: $color-grey-primary-light;
      border-color: $color-grey-secondary-extra-light;

      &:hover {
        background-color: $color-grey-primary-light;
        border-color: $color-grey-secondary-extra-light;
      }
    }
  }
}

@import '../../styles';

.report__analysis {
  display: flex;
  gap: 64px;

  .sub-menu__title {
    margin-bottom: 8px;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 0;

    .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
      border-color: $color-blue-secondary;
    }
  }
}

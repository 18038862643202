@import '../../../../styles/';

.file-loader__filters {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  width: 100%;

  .ant-row.ant-form-item {
    margin-bottom: 0;

    .ant-col.ant-form-item-control {
      margin-bottom: 0;

      .ant-form-item-control-input {
        .ant-radio-group label + label:not(:first-child) {
          border-top: 1px solid $color-grey-secondary-extra-light;
        }

        .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          border-color: $color-blue-secondary;
        }

        .ant-radio-group.ant-radio-group-solid {
          flex-direction: row;
        }
      }
    }
  }

  .filters__search {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    gap: 16px;

    .input__date-container {
      width: 25%;
    }

    .ant-row.ant-form-item:first-child {
      flex: 1;
    }
  }

  .filters__date-inputs {
    display: flex;
    gap: 16px;
    align-items: flex-end;

    div {
      &:nth-child(3) .dropdown {
        width: 120px;
      }

      &:nth-child(4) .dropdown {
        width: 80px;
      }
    }
  }
}

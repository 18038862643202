@import '../../styles';

.flaws__menu {
    width: 380px;
    height: 100%;
    padding: 16px;
    background-color: $color-white-primary;

    .flaws__menu__title {
        margin-bottom: 12px;
    }

    .ant-row {
        margin-bottom: 0;

        .ant-form-item-label label {
            line-height: 14px;
            color: $color-grey-primary-deep;
        }

        .ant-form-item-control .ant-form-item-control-input {
            min-height: 0;
        }
    }
}

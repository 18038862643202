@import '../../styles';

#cancelProcess {
  position: absolute;
  z-index: 300;
  top: calc(50% + 96px);
  left: 50%;
  transform: translate(-50%, -60%);
  background: $color-blue-secondary;
  border-color: $color-blue-secondary;
  transition: opacity 0.2s ease-in;
  font-size: 16px;
  height: 40px;

  &:hover {
    opacity: 0.9;
  }

  &[disabled] {
    color: $color-white-primary;
    background: $color-grey-secondary-deep;
    border-color: $color-grey-secondary-deep;

    &:hover {
      opacity: 1;
    }
  }
}

@import '../../styles';

.report__logo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;

  .sub-menu__title {
    margin-bottom: 0;
  }

  .ant-row.ant-form-item {
    width: 320px;
    margin-bottom: 4px;
  }

  .report__email-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 16px;
  }

  .ant-btn.ant-btn-default {
    background-color: $color-white-primary;
    color: $color-black-primary;
    border-color: $color-grey-secondary-extra-light;

    &:focus {
      background-color: inherit;
      color: inherit;
      border-color: $color-grey-secondary-extra-light;
    }
  }
}

.report__logo__section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .report__logo__change {
    display: flex;
    gap: 40px;

    img {
      background-color: $color-white-primary;
      align-self: flex-start;
      width: 240px;
      height: 28px;
      object-fit: contain;
      object-position: left;
    }
  }
}

@import './src/styles';

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 32px;
  color: $color-black-secondary;
  border: 1px solid $color-grey-primary;
  transition: background-color 0.3s, border-color 0.3s;
  user-select: none;

  &.active {
    .dropdown__menu {
      display: block;
      position: absolute;
      width: inherit;
      top: 32px;
      left: 0;
      z-index: 9;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $color-grey-primary-extra-light;
    border-color: $color-grey-primary-extra-light;
  }

  &.disabled {
    background-color: $color-grey-primary-light;
    color: $color-grey-primary-deep;
    cursor: not-allowed;
    border: 1px solid $color-grey-primary;
  }

  .dropdown__menu {
    display: none;
    position: absolute;
    width: inherit;
    top: 32px;
    left: 0;
  }
}

@import './src/styles';

.flaws__menu__flaw-area {
  // margin-bottom: 20px;

  .flaws__menu__region {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div:first-child {
      font-size: 12px;
      line-height: 14px;
      color: $color-grey-primary-deep;
    }

    .flaws__menu__region__controls {
      display: flex;
      gap: 8px;

      button {
        width: 48px;
        height: 24px;
        border: 1px solid $color-grey-secondary-extra-light;
        background: $color-white-primary;

        svg {
          color: $color-black-primary;
          font-size: 20px;
        }
      }
    }
  }

  .flaws__menu__inputs-wrapper {
    display: flex;
    gap: 8px;
    margin-top: 8px;

    .ant-row {
      width: calc((100% - 32px) / 4);
      margin-bottom: 0;

      label {
        text-transform: none;
      }
    }
  }
}

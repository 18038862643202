@import './src/styles';

.statistics__selected-area {
  display: flex;
  border: 1px solid $color-grey-secondary-extra-light;
  padding: 2px 0;

  .column {
    width: 100%;

    &.first-column {
      border-right: 1px solid $color-white-secondary;
    }

    .statistics__list {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 12px;
      line-height: 20px;
      padding: 0 16px;
      margin-bottom: 0;

      .statistics__item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 5px 0;
        border-bottom: 1px solid $color-white-secondary;

        &:last-child {
          border-bottom: none;
        }

        p {
          margin-bottom: 0;

          &:first-child {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.disable-screen__modal {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 500;
  cursor: wait;
}

@import '../../styles';

.images-info__container {
  background-color: $color-white-primary;
  padding: 16px;
  width: 100%;
  height: 35%;

  .side-menu__title {
    margin-bottom: 16px;
  }
}

@import './src/styles';

.distance-group {
  pointer-events: all;
  cursor: pointer;

  text {
    fill: $color-black;
    font-size: 14px;
  }

  line {
    stroke: $color-green-light;
    stroke-width: 2px;
    stroke-dasharray: 3 3;
  }

  circle {
    fill: $color-yellow-primary;
  }

  &.active {
    line {
      stroke: $color-red-primary;
    }

    circle {
      fill: $color-red-primary;
    }
  }
}

#line-x,
#line-y {
  stroke: $color-grey-secondary;
  stroke-width: 1;
  stroke-dasharray: 4 1;
}

@import '../../../../../styles';

.entire__statistics {
  .entire__statistics__table {
    border: 1px solid $color-grey-secondary-extra-light;
    padding: 0 8px;

    .entire__statistics__table__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color-white-secondary;

      p {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;

        &:nth-child(1) {
          display: block;
          width: 40%;
          font-weight: 600;
        }
      }

      &:first-child {
        height: 40px;

        p {
          font-weight: 600;
          padding: 0;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

@import './src/styles';


.toolbar__wrapper__secondary {
  display: flex;
  transform: translateY(-100%);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  height: 64px;
  padding: 16px 10px 0px;
  transition: transform .3s ease-in ;
  background-color: $color-white-primary;
  //-webkit-box-shadow: 0px 5px 5px -5px rgba(34, 60, 80, 0.6) inset;
  //-moz-box-shadow: 0px 5px 5px -5px rgba(34, 60, 80, 0.6) inset;
  //box-shadow: 0px 5px 5px -5px rgba(34, 60, 80, 0.6) inset;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);

  &.shown {
    display: flex;
    transform: translateY(0);
  }
}
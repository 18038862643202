@import '../../styles';

.db-container.height-analysis-container {
  padding: 10px;
  background-color: $color-grey-primary;

  .ant-tabs-nav {
    margin: 0 !important;
  }

  #heightAnalysis-horizontal, #heightAnalysis-vertical {
    width: 100%;
    height: 100%;
    background-color: $color-white-primary;

    path,
    .tick line {
      stroke: $color-grey-secondary-deep;
    }

    text {
      fill: $color-grey-secondary-deep;
      font-size: 16px;
      user-select: none;
    }

    svg text {
      fill: $color-black;
    }
  }
}

.backwall__menu__inner .backwall-flaws__thresholds {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .side-menu__title {
    margin-bottom: 12px;
  }

  .backwall-flaws__thresholds__inputs {
    display: flex;
    gap: 8px;
  }
}

@import '../../styles';

.zones__container {
  .zones__menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 320px;
    height: 100%;
    padding: 16px;
    background-color: $color-white-primary;

    .remarks .ant-row.ant-form-item .ant-form-item-label label {
      font-size: 16px;
      line-height: 24px;
    }

    .ant-btn.ant-btn-default {
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-black-primary;
      width: 100%;
      height: 34px;
      padding: 0 5px;
      border-color: $color-grey-secondary-extra-light;
      background: transparent;
      transition: background-color .3s ease-in, border-color .3s ease-in;

      &:last-child {
        margin-bottom: 0;
        border-left: 1px solid inherit;
      }

      & + .ant-btn {
        margin-left: 0;
      }

      &:hover {
        background-color: $color-blue-secondary !important;
        border-color: transparent !important;
        color: $color-white-primary !important;
      }
    }

    .side-menu__title {
      margin-bottom: 12px;
    }

    .zones__control, .zones__zones, .coordinate__system, .use-in {
      margin-bottom: 42px;
    }

    .zones__zones, .coordinate__system, .use-in {
      .side-menu__title {
        font-size: 16px;
        line-height: 24px;
      }

      button {
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .use-in {
      .side-menu__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .legend {
          width: 16px;
          height: 16px;

          &.seg {
            background-color: $color-red;
          }

          &.flaw {
            background-color: $color-green-primary;
          }

          &.crack {
            background-color: $color-yellow;
          }

          &.backwall {
            background-color: $color-orange;
          }
        }
      }
      .ant-row.ant-form-item {
        margin-bottom: 0;

        .ant-form-item-control .ant-form-item-control-input {
          min-height: 0;

          label.ant-checkbox-wrapper {
            color: $color-black-primary;
            font-size: 16px;
            line-height: 24px;
            height: auto;

            .ant-checkbox .ant-checkbox-inner {
              border-color: $color-grey-secondary-extra-light;
            }
          }
        }
      }
    }
  }

  .common__graph__wrapper {
    width: calc(100% - 10px - 320px);
  }
}
@import '../../styles';

.segregation__menu {
    width: 380px;
    height: 100%;
    padding: 16px;
    background-color: $color-white-primary;

    .segregation__menu__title {
        margin-bottom: 12px;
    }

    .ant-row.ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label label {
            line-height: 14px;
            color: $color-grey-primary-deep;
        }

        .ant-form-item-control .ant-form-item-control-input {
            min-height: 0;
        }
    }

    label.ant-checkbox-wrapper {
        color: $color-black-primary;
        font-size: 16px;
        line-height: 24px;
        height: auto;

        .ant-checkbox .ant-checkbox-inner {
            border-color: $color-grey-secondary-extra-light;
        }
    }
}
@import '../../styles';

.adapt__menu {
  width: 320px;
  height: 100%;
  padding: 16px;
  background-color: $color-white-primary;

  .adapt__menu__general, .adapt__menu__cropping {
    margin-bottom: 20px;
  }

  .adapt__menu__title {
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $color-blue-secondary;
    margin-bottom: 10px;
  }

  .ant-btn.ant-btn-default {
    width: 100%;
    height: 34px;
    justify-content: center;
    margin-bottom: 4px;
    background-color: transparent;
    color: $color-black-primary;
    border-color: $color-grey-secondary-extra-light;
    font-size: 16px;

    &:hover {
      color: $color-white-primary !important;
      background-color: $color-blue-secondary !important;
      border-color: transparent !important;
    }

    & + button {
      margin-left: 0;
    }

    &[disabled] {
      background-color: $color-grey-primary-light !important;
      border-color: $color-grey-secondary-extra-light !important;
      color: $color-philippine-grey !important;

      &:hover {
        background-color: $color-grey-primary-light !important;
        border-color: $color-grey-secondary-extra-light !important;
        color: $color-philippine-grey !important;
      }
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 8px;

    .ant-form-item-label {
      label {
        text-transform: none;
        color: $color-grey-primary-deep;
      }
    }

    .ant-input-number {
      width: 100%;
    }
  }

  .adapt__menu__scale {
    .ant-btn.ant-btn-default {
      margin-bottom: 0;
    }
  }

  .adapt__image__accept {
    margin-top: 40px;

    .ant-btn.ant-btn-default {
      color: $color-white-primary;
      background-color: $color-blue-secondary;
      border-color: transparent;

      &[disabled] {
        color: $color-black-primary;
        background-color: $color-grey-primary-light;
        border-color: $color-grey-secondary-extra-light;

        &:hover {
          background-color: $color-grey-primary-light;
          border-color: $color-grey-secondary-extra-light;
        }
      }
    }
  }
}
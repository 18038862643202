.range-bar {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  height: 100%;

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-input-number {
            vertical-align: top;

            .ant-input-number-input-wrap {
              input {
                padding: 0 8px !important;
              }
            }
          }
        }
      }
    }
  }

  &.single-layer {
    .range-slider[data-vertical] {
      .range-slider__range {
        width: 0;
      }

      .range-slider__thumb {
        height: 8px;

        &[data-upper] {
          width: 0;
        }
      }
    }
  }
}
@import './src/styles';

.toolbar__wrapper__primary {
  position: sticky;
  z-index: 3;
  left: 0;
  right: 0;
  display: flex;
  gap: 20px;
  height: 72px;
  padding: 16px 10px;
  background-color: $color-white-primary;
  // for open devtools
  @media screen and (max-width: 1250px) {
    gap: 8px;
  }

  .toolbar__button-group, .toolbar__options {
    display: flex;
    gap: 10px;

    .ant-btn.ant-btn-icon-only {
      //width: 95px;
      width: 40px;
      height: 40px;
      padding: 0;
      justify-content: center;

      & + .ant-btn {
        margin-left: 0;
      }
    }
  }

  .toolbar__options {
    gap: 0;

    .ant-btn.ant-btn-icon-only {
      min-width: 40px;
      padding: 0 5px;
      border-color: $color-grey-secondary-extra-light;
      background-color: transparent;
      transition: background-color .3s ease-in, border-color .3s ease-in;

      & + button {
        border-left: none;
      }

      &:hover {
        background-color: $color-grey-primary-extra-light;
        border-color: $color-grey-primary-extra-light;
      }

      &[disabled] {
        background-color: $color-grey-primary-light !important;
        border-top-color: $color-grey-primary-extra-light !important;
        border-bottom-color: $color-grey-primary-extra-light !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;

        //svg g g {
        //  fill: black;
        //}

        //#ruler path {
        //  fill: $color-black;
        //}

        div {
          //border-color: $color-black !important;
          border-color: $color-black;
        }

        & + button:not([disabled]) {
          border-left: 1px $color-grey-secondary-extra-light solid !important;
        }

        & + button[disabled] {
          border-left-color: transparent !important;
          border-right-color: transparent !important;
        }
      }
    }
  }

  .ant-radio-wrapper {
    margin-right: 0;
    height: 40px;
    border: 1px solid $color-grey-secondary-extra-light;

    &.ant-radio-wrapper-checked {
      color: $color-white-primary;
      background-color: $color-blue-primary;
      border-color: transparent;
    }

    .ant-radio {
      display: none;

      & + span {
        font-size: 16px;
        padding: 0 24px;

        // for open devtools
        @media screen and (min-width: 1450px) and (max-width: 1600px) {
          padding: 0 16px;
        }
        // for open devtools
        @media screen and (max-width: 1449px) {
          padding: 0 8px;
        }
      }
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 0;

    .ant-form-item-control-input {
      align-items: normal;

      .ant-radio-group {
        height: 40px;
        margin-right: 0;

        &.ant-radio-group-solid .ant-radio-button-wrapper {
          display: flex;
          align-items: center;
          padding: 0 24px;
          height: 40px;

          .ant-radio-button + span {
            font-size: 16px;
            color: $color-black-primary;
          }

          .ant-radio-button-checked {
            & + span {
              color: $color-white-primary;
            }
          }
        }
      }
    }
  }

  .collapseWrapper {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    svg {
      font-size: 36px;
    }
  }

  .radio-group {
    width: auto;
    gap: 0;

    label {
      height: 100%;

      & + label {
        border-left: 0 solid;
      }
    }
  }

  .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled {
    background-color: $color-grey-primary-light;
    border-color: $color-grey-secondary-extra-light;

    span + span {
      color: inherit !important;
    }
  }
}

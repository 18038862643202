@import '../../styles/index';

.geometry__control {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% / 6);
  padding: 16px;
  background-color: $color-white-primary;
  height: 100%;

  .geometry__control__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: $color-blue-secondary;
    margin-bottom: 12px;
  }

  p {
    margin: 16px 0 8px 0;
  }

  .ant-btn.ant-btn-default {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-black-primary;
    width: 100%;
    height: 34px;
    padding: 0 5px;
    border-color: $color-grey-secondary-extra-light;
    background: transparent;
    transition: background-color .3s ease-in, border-color .3s ease-in;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    & + .ant-btn {
      //border-top: none;
      margin-left: 0;

    }

    &:hover {
      background-color: $color-blue-secondary;
      border-color: $color-blue-secondary;
      color: $color-white-primary;
    }

    &:focus {
      color: inherit;
      border-color: $color-grey-secondary-extra-light;
      background-color: inherit;
    }
  }
}
@import './src/styles';

.toolbar {
    position: relative;

    .YPpl.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: $color-blue-primary;
        border-color: $color-blue-primary;
    }
}

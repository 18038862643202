.report__form {
  display: flex;
  max-width: 1272px;
  width: 100%;
  gap: 40px;

  .report__form__inputs {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    gap: 16px;
  }

  .ant-row {
    flex: 1 0 auto;

    .ant-form-item-control-input {
      height: 100%;

      .ant-form-item-control-input-content {
        height: 100%;

        .ant-input {
          height: 100%;
        }
      }
    }
  }
}

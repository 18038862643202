.block-in-2 {
  path:nth-child(2) {
    stroke-dasharray: 40 40;
  }

  path:nth-child(3) {
    stroke-dasharray: 0 40 40 0;
  }
}

.block-in-3 {
  path:nth-child(2) {
    stroke-dasharray: 30 60;
  }

  path:nth-child(3) {
    stroke-dasharray: 0 30 30 30;
  }

  path:nth-child(4) {
    stroke-dasharray: 0 60 30 0;
  }
}

.block-in-4 {
  path:nth-child(2) {
    stroke-dasharray: 20 60;
  }

  path:nth-child(3) {
    stroke-dasharray: 0 20 20 40;
  }

  path:nth-child(4) {
    stroke-dasharray: 0 40 20 20;
  }

  path:nth-child(5) {
    stroke-dasharray: 0 60 20 0;
  }
}
@import './src/styles';

.db-container {
  width: 100%;
  background-color: $color-white-primary;
  padding: 16px;

  .ant-btn.ant-btn-default {
    display: flex;
    justify-content: center;
    background-color: $color-white-primary;
    margin-left: 0;
    border-color: $color-grey-secondary-extra-light;
    color: $color-black;

    &:hover {
      color: $color-white-primary;
      background-color: $color-blue-secondary;
      border-color: $color-blue-secondary;
    }

    &:focus {
      color: inherit;
      border-color: $color-grey-secondary-extra-light;
      background-color: inherit;
    }

    &:disabled {
      background-color: $color-grey-primary-light;
      border-color: $color-grey-secondary-extra-light;
      color: $color-philippine-grey;
    }
  }
}

@import '../../../../styles/index.scss';
.cracks__statistics__modify-cracks {
    display: flex;
    flex-direction: column;
    gap: 42px;
    .cracks__statistics__modification {
        .statistics__modification__buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            button {
                width: calc(50% - 4px);
                &:last-child {
                    width: 100%;
                }
                &[disabled] {
                    background-color: $color-grey-primary-light;
                    border-color: $color-grey-primary-light;
                    color: $color-black-primary;
                }
            }
        }
    }
    .cracks__statistics__type {
        .cracks__statistics__type__types {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .ant-radio-wrapper {
                line-height: 22px;
                height: 22px;
            }
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .label {
                    width: 16px;
                    height: 16px;
                    background-color: black;
                }
            }
        }
    }
}

.snapshots-viewer__modal {
  height: 100%;
  top: 16px !important;

  .ant-modal-content {
    height: calc(100% - 16px);

    .ant-modal-body {
      height: calc(100% - 72px - 88px);

      #snapshotComparison {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 20px;
        overflow-y: auto;

        .snapshots-viewer__snapshot-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .react-transform-wrapper, .react-transform-component {
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          image-rendering: pixelated;
          object-fit: contain;
        }
      }
    }
  }

  .titles__wrapper {
    display: flex;
    flex-wrap: wrap;

    //.ant-form-item {
    //  width: 50%;
    //}
  }
}

@import '../../../styles/';

.input__date-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
    color: $color-black-text;
  }

  .clear-date-button {
    position: absolute;
    right: 40px;
    bottom: 8px;
    border: 0;
    padding: 0;
    background-color: transparent;
    height: 18px;
    width: 18px;
    opacity: 0;
    transition: opacity 0.3s, color 0.3s;

    &::after {
      height: 18px;
      width: 18px;
      background-color: #a8a8a8;
      font-size: 18px;
      line-height: 16px;
      content: '×';
      color: $color-white-primary;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      top: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  .input__date__icon {
    position: absolute;
    right: 16px;
    bottom: 8px;

    svg {
      color: #a8a8a8;
      width: 18px;
      height: 18px;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      input {
        width: 100%;
        padding: 0px 16px;
        background-color: $color-grey-primary-extra-light;
        border: 1px solid transparent;
        border-bottom-color: $color-philippine-grey;
        height: 32px;
        border-radius: 0;

        &:focus {
          border-color: $color-blue-secondary;
          outline: 0;
          box-shadow: none;
          border-width: 1px;
        }
      }
    }

    &:hover + .clear-date-button {
      opacity: 1;
    }
  }

  .react-datepicker-popper {
    padding-top: 4px;
    z-index: 10;

    .react-datepicker {
      padding: 0px;
      background: $color-grey-primary-extra-light;
      border-radius: 0;
      border: 1px solid $color-white-secondary;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
      color: $color-black-text;
      font-size: 14px;
      line-height: normal;
      font-weight: 400;
      line-height: 32px;

      .react-datepicker__navigation {
        top: 0;

        .react-datepicker__navigation-icon::before {
          top: 14px;
          border-width: 1.5px 1.5px 0 0;
          width: 7px;
          height: 7px;
          border-color: $color-black-text;
        }
      }

      .react-datepicker__month-container {
        .react-datepicker__header {
          height: 64px;
          padding: 0;
          border-bottom: 0;
          background: transparent;

          .react-datepicker__current-month {
            color: inherit;
            font-weight: 400;
            line-height: inherit;
            font-size: inherit;
            border-bottom: 1px solid $color-grey-primary;
          }

          .react-datepicker__day-names {
            .react-datepicker__day-name {
              color: inherit;
              font-size: inherit;
              line-height: inherit;
              height: 32px;
              width: 36px;
              margin: 0;
            }
          }
        }

        .react-datepicker__month {
          margin: 0;

          .react-datepicker__day {
            height: 32px;
            width: 36px;
            margin: 0;
            line-height: inherit;
            font-size: inherit;
            color: inherit;
            border-radius: 0;

            &.react-datepicker__day--selected {
              color: $color-white-primary;
              background-color: $color-blue-secondary;
            }

            &.react-datepicker__day--today {
              background-color: $color-grey-primary;
              color: #161616;
              font-weight: 400;
            }

            &:hover {
              background-color: $color-grey-primary;
            }

            &.react-datepicker__day--keyboard-selected {
              background-color: transparent;
            }
          }
        }
      }
    }

    .react-datepicker__today-button {
      font-weight: inherit;
      height: 32px;
      border-top: 1px solid $color-grey-primary;
      line-height: inherit;
      color: $color-blue-secondary;
      padding: 0;
      background-color: transparent;
    }
  }
}

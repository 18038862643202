@import './src/styles';

.database-results__content {
  margin-top: 80px;
  overflow-x: auto;

  table {
    margin-top: 20px;
    color: $color-black-text;
    border: 1px solid $color-grey-primary;
    font-weight: 400;
    white-space: nowrap;

    tr {
      height: 40px;
      border-bottom: 1px solid $color-grey-primary;

      &:nth-child(odd) {
        background-color: $color-grey-primary-extra-light;
      }
    }

    th {
      user-select: none;
      position: relative;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      padding: 0 24px;
      width: calc((100% - 36px) / 15);

      &.active {
        color: $color-white-primary;
        background-color: $color-blue-secondary;

        &:hover {
          .icon-wrapper {
            display: none;
          }
        }
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }

      .icon-wrapper {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
      }

      svg {
        display: block;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translate(0,-50%);
        font-size: 16px;
        transition: opacity 0.3s ease-in;
      }
    }

    td {
      text-align: center;
      padding: 0 24px;
      width: calc((100% - 36px) / 15);

      &.active {
        color: $color-white-primary;
        background-color: $color-blue-secondary;
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .wrapper {
        height: 80px;
        overflow-y: auto;

        .inner__wrapper:not(:last-child) {
          border-bottom: 1px solid $color-grey-primary;
        }

        div {
          margin-bottom: 8px;
        }
      }

      .remarks__wrapper {
        width: 100px;
        overflow-x: auto;
      }
    }
  }
}

.database-results__controls {
  position: relative;
  display: flex;
  gap: 8px;

  .table-controller {
    .ant-tree {
      top: 40px;
      z-index: 2;
    }
  }

  .ant-tree {
    position: absolute;
    z-index: 5;
    right: 0;
    left: 0;
    width: 100%;

    .ant-tree-list {
      overflow-y: auto;
      max-height: 85vh;

      //.ant-tree-treenode-leaf-last:first-child {
      //  .ant-tree-checkbox {
      //    //display: none;
      //  }
      //}

      .ant-tree-title {
        overflow: visible !important;

        .title-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
        }
      }

      .db__additional-filter__wrapper {
        height: 38px;
        display: flex;
        gap: 8px;
        align-items: center;

        .input__date-container {
          width: 250px;
          align-items: flex-end;
          gap: 8px;
          flex-direction: row;

          input {
            background-color: $color-white-primary;
          }
        }

        .db__additional-filter__name {
          width: 220px;
        }
      }

      .db__additional-filters__controls {
        display: flex;
        gap: 8px;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
      flex-direction: row;
      align-items: center;

      label {
        margin-bottom: 0;
      }

      input, .ant-select-selector {
        background-color: $color-white-primary;
      }
    }
  }
}

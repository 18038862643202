.message__modal {
  .ant-modal-content {
    .ant-modal-title {
      margin-left: 38px;
    }

    .ant-modal-body {
      min-height: 16px !important;

      svg {
        position: absolute;
        top: 24px;
      }
    }

    .ant-modal-footer {
      button:first-child {
        display: none;
      }
    }
  }
}

@import '../../../styles/index';
.common__graph {
  position: relative;
  width: calc(100% - 50px);
  height: calc(100% - 40px - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;

  .common__graph__main-axis {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  #segGraph,
  #flawsGraph,
  #zonesGraph,
  #adaptImageGraph,
  #cracksGraph,
  #backwallGraph {
    //width: calc((100vw - 40px - 88px) / 2 - 32px - 72px - 35px);
    width: 100%;
    //height: calc((100vh - 48px - 72px - 20px) / 2 - 32px - 10px - 22px);
    height: 100%;
    display: block;
    g > path,
    .tick line {
      stroke: $color-grey-secondary-deep;
    }
    text {
      fill: $color-grey-secondary-deep;
      font-size: 16px;
      user-select: none;
    }
    svg text {
      fill: $color-black;
    }
    .polygons__group {
      text {
        font-size: 1px;
      }
    }
    .round-zones__text,
    .rect-zones__text {
      font-size: 14px;
    }
  }
  .legend--y,
  .legend--x {
    font-size: 16px;
    line-height: 22px;
    color: $color-grey-secondary-deep;
  }
  .legend--x {
    position: relative;
    left: 36px;
    margin-top: 8px;
  }
  .legend--y {
    position: relative;
    left: 16px;
    width: 55px;
    height: 22px;
    transform: rotate(-90deg);
  }
}

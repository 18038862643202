@import '../../styles/index';

.backwall__menu {
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 100%;
  padding: 16px;
  background-color: $color-white-primary;

  .backwall__menu__inner {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .side-menu__title {
      margin-bottom: 0;
    }

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }

    .ant-btn.ant-btn-default {
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-black-primary;
      flex: 1 1 auto;
      height: 24px;
      padding: 0 5px;
      border-color: $color-grey-secondary-extra-light;
      background: transparent;
      transition: background-color 0.3s ease-in, border-color 0.3s ease-in;

      & + .ant-btn {
        margin-left: 0;
      }

      &:hover {
        background-color: $color-blue-secondary;
        border-color: $color-blue-secondary;
        color: $color-white-primary;
      }

      &:focus {
        color: inherit;
        border-color: $color-grey-secondary-extra-light;
        background-color: inherit;
      }
    }
  }
}

@import '../../styles';

.snapshots {
  .snapshots-view__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 330px);
    background-color: $color-white-primary;
    padding: 10px;

    .snapshots-view__content {
      max-height: calc(100% - 32px - 20px - 20px);
      overflow-y: auto;

      .snapshots-view__row__title {
        font-size: 16px;
        font-weight: 600;
      }

      .snapshots-view__row__content {
        display: flex;
        gap: 20px 0;
        flex-wrap: wrap;
        margin-bottom: 16px;

        .snapshots-view__row-item__wrapper {
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;

          &.selected {
            .img__wrapper {
              background-color: $color-grey-primary;
            }

            .snapshots-view__row-item__title {
              color: $color-blue-secondary;
            }
          }

          .snapshots-view__row-item__title {
            text-align: center;
            margin-top: 8px;
          }

          .img__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            width: 116px;
            height: 116px;
            border-radius: 8px;

            img {
              width: 100%;
              aspect-ratio: auto;
            }
          }
        }
      }
    }

    .snapshots-view__controls {
      display: flex;
      gap: 10px;
    }
  }
}

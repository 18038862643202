@import './src/styles';

.projection-item {
  width: calc(50% - 5px);
  padding: 16px;
  height: calc((100% - 10px) / 2);
  background-color: $color-white-primary;
  position: relative;

  .casting-direction__tooltip {
    position: absolute;
    top: 18px;
    right: auto;
    left: 40px;

    &.entry {
      top: 16px;
      left: 50px;
    }
  }

  .double-arrow {
    &.vertical {
      width: 40px;
      transform: rotate(-90deg);
    }

    &.horizontal {
      width: 45px;
    }
  }

  .projection-item__container {
    display: flex;
    height: 100%;

    .projection-item__wrapper {
      height: 100%;
      margin-right: 35px;


    }
  }

  .projection-item__title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}
@import './src/styles';
.x-spinner {
  position: absolute;
  display: block;
  z-index: 202;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  top: 0;
  bottom: 0;
}

.x-spinner__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  display: block;
  width: 48px;
  height: 48px;
  transition: opacity 0.3s, visibility 0.5s 0.1s;
}

.x-spinner__spin {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-image: url('../../../assets/img/logo.svg');
    background-size: 48px 48px;
    background-position: 50% 50%;
  }
}

.x-spinner__item {
  font-size: 0px;
  border: 3px solid rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  width: 96px;
  height: 96px;
  animation: spin 2s linear infinite;
  padding: 0px;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  margin: -24px 0 0 -24px;
  border-top: 3px solid $color-blue-secondary;
  border-bottom: 3px solid $color-blue-secondary;
}

@import '../../../styles/index';

.cracks__menu__settings {
  display: flex;
  gap: 12px;
  flex-direction: column;

  .side-menu__title {
    margin-bottom: 0;
  }

  .ant-form-item-control-input-content {
    height: 32px;
  }

  .cracks__menu__settings__analysis {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }
}

.db__container {
  gap: 48px;
  display: flex;

  .db__form-section {
    display: flex;
    flex-direction: column;

    .side-menu__title {
      margin-bottom: 14px;
    }

    .sub-menu__title {
      margin-bottom: 0;
    }

    .db__form {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      column-gap: 24px;
      flex-wrap: wrap;
      max-height: 840px;

      .db__form-input {
        display: flex;
        flex-direction: column;

        .db__form-choices {
          display: grid;
          gap: 4px;
          grid-template-columns: 1fr 1fr;
        }

        p {
          margin-bottom: 4px;
        }
      }
    }

    .ant-row {
      margin-bottom: 0;
      width: auto;

      .ant-form-item-no-colon {
        margin-bottom: 6px;
      }

      .ant-checkbox-wrapper {
        height: 28px;
        line-height: 28px;
      }
    }
  }
}

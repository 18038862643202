@import 'src/styles';

.ant-modal.geometry-analysis__modal {
  .ant-modal-content {
    .ant-modal-close {
      top: 10px;
      right: 12px;
    }

    .ant-modal-header {
      height: auto;
      padding: 24px 32px;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .ant-modal-body {
      min-height: 170px;
      padding: 0 32px;

      .geometry-analysis__radio-group {
        display: flex;
        flex-wrap: wrap;
        row-gap: 16px;

        .ant-radio-wrapper {
          width: 186px;
        }
      }
    }

    .ant-modal-footer {
      //justify-content: center;
      padding: 0 32px 24px !important;

      button {
        height: 40px;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
      }

      button:first-child {
        display: none;
      }
    }
  }
}

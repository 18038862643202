@import '../../../styles/';

.context {
  position: absolute;
  background-color: $color-white-primary;
  border: 1px solid $color-grey-secondary-extra-light;

  .context__item {
    padding: 6px 24px;

    &:hover {
      background-color: $color-grey-primary-light;
    }
  }
}

@import '../../styles/index';

.geometry__result {
  width: calc(100% * 5 / 6);
  padding: 10px;
  background-color: $color-white-primary;
  height: 100%;

  .ant-tabs {
    height: 95%;

    .ant-tabs-nav {
      padding: 0px 16px !important;

      .ant-tabs-tab {
        margin: 0px !important;
        padding: 0px 16px !important;
      }
    }
  }

  #geometryBase,
  #geometryALines,
  #geometryBLines,
  #geometryCLines,
  #geometryDiagonals,
  #geometryGeometrydimensions,
  #geometryBilletCut {
    width: 100%;
    //width: 1000px;
    height: 100%;
    background-color: white;

    path,
    .tick line {
      stroke: $color-grey-secondary-deep;
    }

    text {
      fill: $color-grey-secondary-deep;
      font-size: 16px;
      user-select: none;
    }

    svg text {
      fill: $color-black;
    }
  }

  #geometryBase,
  #geometryDiagonals {
    svg text {
      fill: $color-white-primary;
    }
  }

  #geometryBilletCut {
    svg path {
      fill-opacity: 0.8;
    }
  }
}

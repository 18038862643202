@import '../../styles';

.images-list__container {
  width: 100%;
  height: 65%;
  background-color: $color-white-primary;
  padding: 16px;

  .images-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: calc(100% - 28px - 10px - 10px - 74px - 4px);
    overflow-y: auto;
  }

  .images-list__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      display: flex;
      justify-content: center;
      margin-left: 0;
      background-color: $color-white-primary;
      border-color: $color-grey-secondary-extra-light;
      color: $color-black;

      &:first-of-type {
        background-color: $color-blue-secondary;
        color: $color-white-primary;
        border: none;

        &:focus {
          background-color: $color-blue-secondary;
          color: $color-white-primary;
          border: none;
        }
      }

      &:hover {
        color: $color-white-primary;
        background-color: $color-blue-secondary;
        border-color: $color-blue-secondary;
      }

      &:focus {
        color: inherit;
        border-color: $color-grey-secondary-extra-light;
        background-color: inherit;
      }
    }
  }
}

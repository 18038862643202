@import '../../styles/';
.settings__section {
  display: flex;
  gap: 48px;

  div {
    .settings__options {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 16px;
      column-gap: 24px;
      max-height: 630px;
      width: max-content;
      margin-bottom: 12px;

      .ant-row .ant-input-number {
        width: 100%;
      }
    }

    &.backwall-settings {
      .settings__options {
        max-height: 260px;
      }
    }

    .side-menu__title {
      margin-bottom: 20px;
    }
  }
}

@import './src/styles';

.recipes__idv-files {
  display: flex;
  flex-direction: column;
  align-items: center;

  .recipes__idv-actions {
    width: 100%;
    position: relative;
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    padding: 16px;

    button {
      text-transform: uppercase;
      font-size: 12px;
    }

    .ant-row.ant-form-item {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;

      .ant-col.ant-form-item-label {
        label {
          margin-bottom: 0;
        }
      }

      .ant-form-item-control-input {
        min-height: 22px;

        button {
          height: 22px;
          min-width: 44px;
          background-color: $color-grey-secondary;

          &.ant-switch-checked {
            background-color: $color-blue-primary;
          }

          .ant-switch-handle {
            top: 1px;
          }
        }
      }
    }
  }
}

@import '../../../styles';

.images-list__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  padding: 3px 16px;
  height: 50px;

  &.active {
    background-color: $color-grey-primary-light;
    border-left: 3px solid $color-blue-secondary;
    padding-left: 13px;
  }

  span {
    line-height: 22px;
  }
}
